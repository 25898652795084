.cc-window {
    background-color: $cookie-color-bg;
    color: $cookie-color-text;
    opacity: 1;
    @include prefix(( transition: opacity 1s ease ));

    &.cc-floating {
        @include prefix(( box-shadow: 4px 4px 14px -2px #000 ));
    }

    .cc-link {
        color: $cookie-color-link;

        &:hover {
            color: $cookie-color-link-hover;
        }
    }

    .cc-compliance {
        .cc-btn {
            background-color: $cookie-color-btn-bg;
            border-color: $cookie-color-border;
            color: $cookie-color-btn;

            @include prefix(( transition: all 500ms ));
        }

        &:hover .cc-btn {
            background-color: $cookie-color-btn-bg-hover;
            border-color: $cookie-color-border-hover;
            color: $cookie-color-btn-hover;
        }
    }
}
