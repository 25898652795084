@font-face {
    font-family: 'fontello';
    src: url('../Fonts/fontello.eot?6984843');
    src: url('../Fonts/fontello.eot?6984843#iefix') format('embedded-opentype'),
         url('../Fonts/fontello.woff?6984843') format('woff'),
         url('../Fonts/fontello.ttf?6984843') format('truetype'),
         url('../Fonts/fontello.svg?6984843#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
}

.mail:before,
[class^="icon-"]:before,
[class*=" icon-"]:before,
[class^="fa-"]:before,
[class*=" fa-"]:before {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .1em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: .1em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-mail:before, .mail:before, .icon-mail-1:before { content: '\e803'; }
.icon-at:before, .fa-at:before { content: '\e800'; margin-top: -3px; }
.icon-gplus:before, .fa-google-plus:before { content: '\e801'; }
.icon-twitter:before, .fa-twitter:before { content: '\f099'; }
.icon-facebook:before, .fa-facebook:before { content: '\f09a'; }
.icon-angle-up:before, .fa-angle-up:before { content: '\f106'; }
.icon-angle-down:before, .fa-angle-down:before { content: '\f107'; }
.icon-copyright:before, .fa-copyrightat:before { content: '\f1f9'; }
.icon-whatsapp:before, .fa-whatsapp:before { content: '\f232'; }


/* source-sans-pro-300 - latin */
@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 300;
    src: url('../Fonts/source-sans-pro-v11-latin/source-sans-pro-v11-latin-300.eot'); /* IE9 Compat Modes */
    src: local('Source Sans Pro Light'), local('SourceSansPro-Light'),
         url('../Fonts/source-sans-pro-v11-latin/source-sans-pro-v11-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../Fonts/source-sans-pro-v11-latin/source-sans-pro-v11-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('../Fonts/source-sans-pro-v11-latin/source-sans-pro-v11-latin-300.woff') format('woff'), /* Modern Browsers */
         url('../Fonts/source-sans-pro-v11-latin/source-sans-pro-v11-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../Fonts/source-sans-pro-v11-latin/source-sans-pro-v11-latin-300.svg#SourceSansPro') format('svg'); /* Legacy iOS */
  }
  /* source-sans-pro-regular - latin */
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    src: url('../Fonts/source-sans-pro-v11-latin/source-sans-pro-v11-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
         url('../Fonts/source-sans-pro-v11-latin/source-sans-pro-v11-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../Fonts/source-sans-pro-v11-latin/source-sans-pro-v11-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../Fonts/source-sans-pro-v11-latin/source-sans-pro-v11-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../Fonts/source-sans-pro-v11-latin/source-sans-pro-v11-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../Fonts/source-sans-pro-v11-latin/source-sans-pro-v11-latin-regular.svg#SourceSansPro') format('svg'); /* Legacy iOS */
  }
  /* source-sans-pro-600 - latin */
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    src: url('../Fonts/source-sans-pro-v11-latin/source-sans-pro-v11-latin-600.eot'); /* IE9 Compat Modes */
    src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'),
         url('../Fonts/source-sans-pro-v11-latin/source-sans-pro-v11-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../Fonts/source-sans-pro-v11-latin/source-sans-pro-v11-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('../Fonts/source-sans-pro-v11-latin/source-sans-pro-v11-latin-600.woff') format('woff'), /* Modern Browsers */
         url('../Fonts/source-sans-pro-v11-latin/source-sans-pro-v11-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../Fonts/source-sans-pro-v11-latin/source-sans-pro-v11-latin-600.svg#SourceSansPro') format('svg'); /* Legacy iOS */
  }
